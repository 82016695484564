import React, { useEffect, useState } from "react";
import environment from "environment";

/*
Cargamos la variable ambiente en false cuando el domainName contiene preprod,test,dev,localhost  de lo contrario (a2censo.com) True.
y acorde a ella se leen las claves de gtag y googleTagManager respectivamente.
*/
const domainName = window.location.hostname;
const ambiente = !(
  domainName.includes("preprod") ||
  domainName.includes("test") ||
  domainName.includes("localhost") ||
  domainName.includes("dev") ||
  domainName.includes("127")
);
const idGTM = ambiente
  ? environment.gtmContainerId
  : domainName.includes("preprod")
  ? environment.gtmContainerIdPre
  : environment.gtmContainerIdTest;

const GTM = () => {
  const [nonce, setNonce] = useState(null);

  // Función para obtener el nonce desde los encabezados de la respuesta
  const getCSPNonce = async () => {
    try {
      const response = await fetch(window.location.href, { method: "HEAD" });
      return response.headers.get("X-CSP-Nonce");
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    // Obtener el nonce y agregar la metaetiqueta
    getCSPNonce().then((nonce) => {
      if (nonce) {
        setNonce(nonce); // Almacenar el nonce en el estado

        // Crear e insertar la metaetiqueta con el nonce
        const metaTag = document.createElement("meta");
        metaTag.name = "csp-nonce";
        metaTag.content = nonce;
        document.head.appendChild(metaTag);
      }
    });

    // Insertar el iframe de GTM en caso de que no esté habilitado el script
    const newDiv = document.createElement("noscript");
    const newContent = document.createTextNode(
      '<iframe src="https://www.googletagmanager.com/ns.html?id=' +
        idGTM +
        '" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
    );
    newDiv.appendChild(newContent);
    document.body.appendChild(newDiv);
  }, []);

  const tags = `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      j.setAttribute('nonce', '${nonce}');
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${idGTM}');
  `;

  return (
    <div data-testid="google-tag-manager">
      {/* Renderizar el script dinámicamente */}
      {nonce && (
        <script
          type="text/javascript"
          nonce={nonce}
          dangerouslySetInnerHTML={{ __html: tags }}
        />
      )}
    </div>
  );
};

export default GTM;
